/* Menu Styles */

.menu-overlay {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  z-index: 10;
}
.menu-overlay.active {
  display: block; /* Show when active */
}
.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 70%; /* Adjust as needed */
  height: 100%;
  background-image: linear-gradient(to top, #2ca468, #2a406a);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 0 20px;
  z-index: 20;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  color: white;
}
.menu.active {
  transform: translateX(0); /* Slide in */
}

@keyframes flash {
  from {
    opacity: 0.7;
  }

  to {
    opacity: 1;
  }
}
.menu-warning {
  color: rgb(255, 29, 101);
  font-weight: bold;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 20px;
  background-color: rgba(255, 0, 212, 0.121);
  padding: 10px 0;

  animation-name: flash;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.menu-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  margin: 20px 0 10px;
}

.menu-body {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 20px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  padding: 10px 0;
}
.menu-body::-webkit-scrollbar {
  display: none;
}

.beta-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 120px;

  border-radius: 3px;
  background-color: rgba(0, 255, 174, 0.121);
  padding: 10px 0;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}
.beta-section p {
  opacity: .7;
  font-size: 12px;
  margin: 0;
  padding: 10px;
  text-align: center;
  white-space: pre-wrap;
}
.beta-link {
  /* display: flex; */
  /* align-items: center;
  justify-content: space-evenly; */
  padding: 9px;
  padding-right: 28px;
  font-size: 16px;
  background-color: black;
  color: white;
  border: 2px solid #4cf93c;
  border-radius: 24px;
  /* width: 140px; */
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  position: relative;
  margin-top: 10px;
}
.beta-link img {
  position: absolute;
  right: 6px;
  top: 0;
}
.beta-flag {
  position: absolute;
  left: -55px;
  background: rgb(51, 206, 188);
  text-align: center;
  width: 150px;
  transform: rotate(-40deg);
  font-weight: bold;
}

.free-button {
  border-color: white;
  background-color: transparent;
}

.buy-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;

  border-radius: 3px;
  background-color: rgba(0, 255, 174, 0.121);
  padding: 10px 0;
  margin-bottom: 15px;

  position: relative;
  overflow: hidden;
}
.buy-section-disabled {
  opacity: .7;/* beta */
}
.buy-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 10px;
  font-size: 20px;
  background-color: black;
  color: white;
  border: 2px solid #4cf93c;
  border-radius: 24px;
  cursor: pointer;
}
.buy-button:hover {
  opacity: .7;
}
.buy-button span {
  display: flex;
  align-items: flex-end;
}
.buy-button b {
  margin: 0 8px;
}
.buy-button span b {
  margin-left: 0;
  margin-right: 5px;
}
.buy-label {
  border-color: black;
}
.buy-label:hover {
  opacity: 1;
}

.menu-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);

  position: relative;
}

.policies-menu {
  font-size: 10px;
  color: #ffffff83;
  margin-bottom: 10px;
}
.policies-menu a {
  padding: 0 3px;
  color: #ffffff83;
}

.logout-button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  position: relative;
}
.logout-button img {
  position: absolute;
  top: 8px;
  right: 5px;
}

.menu-email {
  position: absolute;
  font-size: 10px;
  color: #ffffff83;
  right: 0px;
  top: -17px;
}



/* List Styles */

.list-overlay {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  z-index: 10;
}
.list-overlay.active {
  display: block; /* Show when active */
}
.list {
  position: fixed;
  top: 0;
  left: 0;
  width: 70%; /* Adjust as needed */
  height: 100%;
  background-image: linear-gradient(to top, #2ca468, #2a406a);
  color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 20;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
}
.list.active {
  transform: translateX(0); /* Slide in */
}

.list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  margin-bottom: 8px;

  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}

.list-body {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 20px;
}
.list-body::-webkit-scrollbar {
  display: none;
}

.list-item {
  color: white;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.077);
  margin-bottom: 10px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 28px;
  position: relative;
}
.list-item:hover {
  opacity: 0.9;
}
.list-item:active {
  opacity: 0.7;
}
.list-item:disabled {
  background-color: rgba(42, 33, 126, 0.46);
  cursor: not-allowed;
  opacity: 0.7;
}
.list-item-debate {
  padding-right: 26px;
}
.debate-indicator {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid white;
  border-radius: 12px;
  background-color: transparent;
}
.debate-indicator img {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 3px;
  top: 2px;
}

.examples-header {
  margin-top: 20px;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  margin-bottom: 8px;
  font-weight: bold;
}
.view-examples {
  text-decoration: underline;
  color: white;
}

.list-body p {
  text-indent: 1em;
  margin: 5px 0;
  font-size: 14px;
}
.list-body b {
  font-size: 13px;
}
.list-body ol {
  margin: 10px 0;
  padding-left: 22px;
}
.list-body ol li {
  font-size: 14px;
  margin-bottom: 10px;
}

.delete-account-button {
  color: white;
  background-color: black;
  padding: 5px;
  margin: auto;
  margin-top: 280px;
  margin-bottom: 40px;
  border: 2px solid white;
  border-radius: 10px;
  font-weight: bold;
  width: 150px;
  opacity: 0.5;
}