@keyframes coin-flip {
  0%, 40%, 80% {
    transform: scaleX(0);
  }
  20%, 100% {
    transform: scaleX(1);
  }
  60% {
    transform: scaleX(-1);
  }
}

.custom-coin {
  width: 20px;
  height: 20px;
  border: 2px solid #47b882;
  border-radius: 50%;
  background: #2b6e50;
  position: relative;
  animation: coin-flip 0.5s ease-in-out;
}
.custom-coin img {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 3px;
}