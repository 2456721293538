:root {
  --header_height: 60px;
}

.App {
  background-color: black;

  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full viewport width */

  background-image: url('./svg/CyberMonk.PNG');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: #5ce4d0;
  width: 100%;
  height: var(--header_height);
  min-height: var(--header_height);
  background-image: linear-gradient(to left, #2ca468, #2a406a);
  border-bottom: 1px solid #4667a6;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.App-header-web {
  height: calc(constant(safe-area-inset-top) + var(--header_height));
  min-height: calc(env(safe-area-inset-top) + var(--header_height));
}
.app-header-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 89px;
  margin-left: 15px;
  margin-bottom: 5px;
}
.app-header-right {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  width: 110px;
  margin-right: 16px;
  margin-bottom: 5px;
}

.topic {
  color: #ffffffc7;
  font-size: 8px;
  font-weight: bold;
  margin-bottom: 0px;
  border: none;
  border-radius: 9px;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64px;
  cursor: pointer;
}
.topic:disabled {
  cursor: not-allowed;
}
.topic-icon {
  left: 13px;
  top: 5px;
  width: 30px;
  height: 30px;
  margin-bottom: 3px;
}
.topic-lock {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 12px;
  top: 2px;
}
.topic-open {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 13px;
  top: -3px;
}
.topic-close {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 8px;
  top: -4px;
  transform: rotate(90deg);
}
.topic-list {
  position: absolute;
  z-index: 1;
  top: 49px;
  right: 0px;
  background-color: black;
  border-radius: 9px;
  padding: 0px 15px;
  width: 150px;
  cursor: default;
}
.topic-list::after {
  content: "";
  position: absolute;
  top: -4px; /* Adjust this to position the tail vertically */
  right: 27px; /* Adjust this to position the tail horizontally */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0; /* Adjust size of the tail */
  border-color: black transparent transparent transparent; /* Match the bubble's color */
  transform: rotate(45deg);
}
.topic-list-header {
  font-size: 11px;
  color: white;
  text-decoration: underline;
  margin: 10px 0px;
}
.topic-item {
  margin-bottom: 10px;
  border: 1px solid white;
  border-radius: 12px;
  padding: 3px 10px;
  font-size: 13px;
  cursor: pointer;
}
.topic-item-selected {
  opacity: 0.7;
  cursor: not-allowed;
}

.new-button {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 2px solid #5ce4d0;
  border-radius: 20px;
  position: relative;
  margin-bottom: 7px;
}
.new-button img {
  position: absolute;
  top: 5px;
  left: 5px;
}

.list-button {
  cursor: pointer;
  margin-bottom: 4px;
  height: 40px;
}

.menu-button {
  cursor: pointer;
  width: 41px;
  height: 38px;
  border: 1px solid white;
  border-radius: 18px;
  margin-bottom: 4px;
  position: relative;
}
.menu-button img {
  position: absolute;
  top: 0;
  left: 0;
}
.menu-button-count {
  position: absolute;
  bottom: -8px;
  right: -17px;
  transform: scale(.6);
}