@keyframes flash-empty {
  from {
    color: white;
    border-color: white;
    opacity: 1;
  }

  to {
    color: rgb(255, 29, 101);
    /* border-color: rgb(255, 29, 101); */
    /* opacity: .7; */
  }
}
.empty {
  animation-name: flash-empty;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.coin-coint {
  display: flex;
  flex-direction: row-reverse;

  border: 2px solid white;
  border-radius: 16px;
  background-color: black;
  padding: 3px;
  width: 65px;
  margin-top: -3px;
  position: relative;
  color: white;

}

.coin-coint .counter {
  margin-right: 2px;
  margin-top: 1px;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  right: 27px;
  bottom: 4px;
}
