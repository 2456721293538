.message-list-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.message-list-container::-webkit-scrollbar {
  display: none;
}

@keyframes fadeInAndGrowSent {
  from {
    opacity: 0;
    transform: scale(0.1) translateX(50%);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadeInAndGrowReceived {
  from {
    opacity: 0;
    transform: scale(0.1) translateX(-50%);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.message {
  margin-bottom: 8px;
  border-radius: 20px;
  max-width: 80%;
  white-space: pre-wrap;
  position: relative;
}
.sent-message {
  padding: 7px 12px;
  background-image: linear-gradient(to right, #2ca468, #007aff);
  color: white;
  align-self: flex-end; /* Aligns sent messages to the right */
  text-align: left;
  animation: fadeInAndGrowSent 0.5s ease-out;
}
.sent-message::after {
  content: "";
  position: absolute;
  bottom: -6px; /* Adjust this to position the tail vertically */
  right: -3px; /* Adjust this to position the tail horizontally */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0; /* Adjust size of the tail */
  border-color: #007aff transparent transparent transparent; /* Match the bubble's color */
  transform: rotate(50deg);
}
.others-message {
  align-self: flex-start;
  background-image: linear-gradient(to left, #2ca468, #007aff);
}
.others-message::after {
  content: "";
  position: absolute;
  bottom: -6px; /* Adjust this to position the tail vertically */
  left: -3px; /* Adjust this to position the tail horizontally */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0; /* Adjust size of the tail */
  border-color: #007aff transparent transparent transparent; /* Match the bubble's color */
  transform: rotate(40deg);
}
.received-message {
  background-color: #3d3d3dcb;
  color: white;
  align-self: flex-start; /* Aligns received messages to the left */
  text-align: left;
  border-radius: 5px;
  padding: 10px 15px;
  animation: fadeInAndGrowReceived 0.5s ease-out;
}

.about-icon {
  position: absolute;
  top: 0px;
  right: -30px;
  background-color: #3d3d3dcb;
  color: white;
  width: 20px;
  height: 20px;
  font-weight: bold;
  padding: 3px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.message-spacer {
  margin-top: 270px;
}

/* waiting indicator*/
.ellipsis {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 11px;
}
.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #007aff; /* Match the color scheme of your chatbot */
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out both;
}
.dot:nth-child(1) {
  animation-delay: -0.32s;
}
.dot:nth-child(2) {
  animation-delay: -0.16s;
}
@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}

@keyframes flash {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.refresh {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  right: -35px;
  top: 0px;
  background-color: #3d3d3dcb;
  cursor: pointer;
  animation: fadeInAndGrowReceived 0.5s ease-out;
}
.refresh img {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 17px;

  animation-name: flash;
  animation-duration: 1.1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.their-turn-indicator {
  margin: 40px 10px;
  background-color: rgba(128, 128, 128, 0.716);
  color: white;
  padding: 20px 10px;
  font-weight: bold;
  border-radius: 3px;
  font-size: 13px;
}
.their-turn-indicator span {
  text-decoration: underline;
  cursor: pointer;
}