.message-container {
  background-image: linear-gradient(to right, #2ca468, #2a406a);
  border-top: 1px solid #364c7a;
  width: 100%;
  display: flex;
}
.message-container-inner {
  width: 100%;
  margin: 7px;/* couldn't do padding on container, so needed inner with margin */
  margin-bottom: calc(constant(safe-area-inset-bottom) + 7px);
  margin-bottom: calc(env(safe-area-inset-bottom) + 7px);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
}
.convo-length-warning {
  position: absolute;
  top: -70px;
  height: 38px;
  width: 250px;
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 10px;
}
.convo-length-warning::after {
  content: "";
  position: absolute;
  bottom: -6px; /* Adjust this to position the tail vertically */
  left: 27px; /* Adjust this to position the tail horizontally */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0; /* Adjust size of the tail */
  border-color: black transparent transparent transparent; /* Match the bubble's color */
  transform: rotate(-135deg);
}
.convo-length-warning span {
  text-decoration: underline;
  cursor: pointer;
}

.message-textarea {
  flex-grow: 1;
  padding: 10px;
  border-radius: 12px;
  background-color: black;
  border-color: black;
  color: white;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  resize: none; /* Prevents manual resizing */
  max-height: 200px;
  margin: 0px;
}
.message-textarea:focus {
  border-color: #5ce4d0; /* or any color of your choice */
}
.message-textarea:focus {
  outline: none;
}

.send-button {
  background-image: linear-gradient(to right, #007aff, #5ce4d0);
  border: none;
  width: 42px;
  height: 40px;
  border-radius: 20px;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 1px;
  position: relative;
  display: flex;
}
.send-button:hover {
  opacity: 0.9;
}
.send-button:active {
  opacity: 0.7;
}
.send-button:disabled {
  background-color: #cccccc; /* Gray color for disabled state */
  color: #c9c9c9;
  cursor: not-allowed;
  opacity: 0.7;
}
.send-button img {
  position: absolute;
  top: 8px;
  left: 9px;
}

@keyframes cost-fadein {
  0% {
    opacity: 0;
  }
  20%, 100% {
    opacity: .5;
  }
  40% {
    opacity: .7;
  }
}
@keyframes cost-fadeout {
  0% {
    opacity: .5;
  }
  10%, 60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.send-cost-indicator {
  position: absolute;
  top: -40px;
  right: -7px;
  color: white;
  background: black;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  width: 50px;
  border-radius: 16px;
  opacity: 0;
  transform: scale(.8);
}
.send-cost-indicator-visible {
  opacity: .5;
  animation: cost-fadein 0.5s ease-in-out;
}
.send-cost-indicator-hide {
  opacity: 0;
  animation: cost-fadeout 1.2s ease-in;
}
.send-cost-indicator b {
  font-size: 20px;
  margin-left: 3px;
}
.send-cost-indicator::after {
  content: "";
  position: absolute;
  bottom: -20px; /* Adjust this to position the tail vertically */
  left: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: black  transparent transparent; /* Match the bubble's color */
  border-width: 10px; /* Adjust size of the tail */
  
}

.send-options {
  position: absolute;
  top: -140px;
  right: 0px;
  height: 108px;
  width: 220px;
  background-color: black;
  color: white;
  border-radius: 35px 35px 15px 35px;
  padding: 10px;
}
.send-options::after {
  content: "";
  position: absolute;
  bottom: -6px; /* Adjust this to position the tail vertically */
  right: 14px; /* Adjust this to position the tail horizontally */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0; /* Adjust size of the tail */
  border-color: black transparent transparent transparent; /* Match the bubble's color */
  transform: rotate(-135deg);
}
.send-option {
  height: 49px;
  border: 2px solid white;
  background-color: black;
  color: white;
  border-radius: 30px;
  text-align: left;
  padding: 7px 16px;
  padding-right: 40px;
  margin-bottom: 10px;
  position: relative;
}
.send-option span {
  position: absolute;
  right: 0px;
  top: -1px;
  height: 45px;
  width: 45px;
  border: 1px solid white;
  border-radius: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}