
.processing-purchase-overlay {
  display: block; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  z-index: 30;
}
.processing-purchase-indicator {
  position: fixed;
  top: 200px;
  right: calc(50vw - 100px);
  width: 200px; /* Adjust as needed */
  height: 110px;
  background-image: linear-gradient(to top, #2ca468, #2a406a);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 40;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: white;
}
.elipsis-processing-container {
  background-color: rgba(255, 255, 255, 0.565);
  padding: 10px 30px;
  border-radius: 5px;
}

.processing-purchase-header {
  display: flex;
  flex-direction: row;
  margin: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  align-items: flex-end;
  font-weight: bold;
  border-bottom: 1px solid white;
}