.open-facilitated {
  color: white;
  background-color: transparent;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  border: 2px solid white;
  border-radius: 5px;
  font-weight: bold;
}

.form-facilitated {
  padding: 10px 7px;
  background-color: rgba(0, 0, 0, 0.071);
  border-radius: 0 0 10px 10px;
  margin: 0 5px;
  border: 1px solid white;
  border-top: none;
}
.form-facilitated-header {
  font-size: 11px;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.521);
  padding: 5px;
  border-radius: 16px 0 16px 0;
}
.form-facilitated-header img {
  width: 16px;
  height: 16px;
  border: 1px solid white;
  padding: 3px;
  border-radius: 15px;
  float: left;
  margin: -2px 6px 2px -2px;
}
.form-facilitated input {
  padding: 5px;
  border-radius: 9px;
  background-color: black;
  border: 1px solid black;
  color: white;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 11px;
  margin: 5px 0;
  width: 80%;
}

.create-facilitated {
  float: right;
  color: white;
  background-color: black;
  padding: 5px 7px;
  margin-top: 10px;
  margin-bottom: 0px;
  border: 2px solid white;
  border-radius: 14px;
  font-weight: bold;
  cursor: pointer;
}
.create-facilitated:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}